const getQuestion = async () => {
    const data = {
        "draw": "1",
        "data": [
            {
                "id": 92966,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 16,
                "point": 4,
                "created_at": "2024-08-07 17:25:30",
                "updated_at": "2024-08-07 17:25:30",
                "no": 1,
                "date_original": "2024-08-07",
                "date": "07 Aug 2024",
                "time": "17:25",
                "question_total": 6,
                "phase_name": "Modul 10 Pengambilan Keputusan Tentang Aeronautika & Risk Management",
                "phase": {
                    "id": 16,
                    "name": "Modul 10 Pengambilan Keputusan Tentang Aeronautika & Risk Management",
                    "start_phase": "2022-09-01 08:44:00",
                    "finish_phase": "2022-09-01 08:44:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 08:44:22",
                    "updated_at": "2023-08-01 14:01:56",
                    "quiz": [
                        {
                          "question": "PAVE adalah model terstruktur untuk pemecahan masalah\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "5 Bidang subyek pengambilan keputusan antara lain Pilot, Pesawat, Lingkungan Operasi, Situasi\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "DECIDE adalah model untuk pemecah masalah\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Merupakan hambatan untuk mempertahankan kepedulian terhadap situasi",
                          "answers": [
                            {
                              "text": "Tidak fokus terhadap membaca peta jalur penerbangan",
                              "isCorrect": false
                            },
                            {
                              "text": "Pemantauan terhadap pesawat selalu dijaga",
                              "isCorrect": false
                            },
                            {
                              "text": "Prioritas dan urutan beban kerja yang sudah sesuai",
                              "isCorrect": false
                            },
                            {
                              "text": "Kelelahan, stress akibat beban kerja",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Ikuti aturan adalah penawar dari sikap kebal\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Operation pitfalls yang memiliki kriteria surprise \nterhadap situasi adalah 'neglect of flight planning, inspection dan \ncheck-list'\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 92812,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 10,
                "point": 4,
                "created_at": "2024-08-07 17:19:44",
                "updated_at": "2024-08-07 17:19:44",
                "no": 2,
                "date_original": "2024-08-07",
                "date": "07 Aug 2024",
                "time": "17:19",
                "question_total": 4,
                "phase_name": "Modul 06 Crew Resources Management",
                "phase": {
                    "id": 10,
                    "name": "Modul 06 Crew Resources Management",
                    "start_phase": "2022-09-01 08:28:00",
                    "finish_phase": "2022-09-01 08:28:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 08:28:36",
                    "updated_at": "2024-08-05 16:03:04",
                    "quiz": [
                        {
                          "question": "Tujuan CRM yang paling benar adalah",
                          "answers": [
                            {
                              "text": "Mengetahui ciri-ciri dan karakter bahaya pada tim kerja",
                              "isCorrect": false
                            },
                            {
                              "text": "Memperbaiki kondisi kerja menjadi lebih baik",
                              "isCorrect": false
                            },
                            {
                              "text": "Berkoordinasi antar tim untuk kelancaran operasi penerbangan",
                              "isCorrect": false
                            },
                            {
                              "text": "Tatakelola sumber daya yang ada untuk menghindari kesalahan personal (human error)",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Checklist PAVE adalah untuk memahami bahaya dalam perencanaan pra-terbang, kecuali",
                          "answers": [
                            {
                              "text": "Aircraft",
                              "isCorrect": false
                            },
                            {
                              "text": "Pilot In Command",
                              "isCorrect": false
                            },
                            {
                              "text": "External Pressure",
                              "isCorrect": false
                            },
                            {
                              "text": "Visual Line Of  Sight",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Risk Mitigation I'M SAFE untuk menentukan kesiapan fisik dan mental, kecuali",
                          "answers": [
                            {
                              "text": "Kesehatan (Illness)",
                              "isCorrect": false
                            },
                            {
                              "text": "Tekanan (Stress)",
                              "isCorrect": false
                            },
                            {
                              "text": "Lingkungan (Environment)",
                              "isCorrect": true
                            },
                            {
                              "text": "Alkohol (Alcohol)",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Berikut merupakan element Crew Resourse Management (Tata kelola sumber daya dalam tim), kecuali",
                          "answers": [
                            {
                              "text": "Human error, pencegahan dan anomali kesalahan operasi",
                              "isCorrect": false
                            },
                            {
                              "text": "Faktor sistem kerja dan organisasi",
                              "isCorrect": false
                            },
                            {
                              "text": "Kepedulian situasi, pemecahan masalah dan pengambilan keputusan",
                              "isCorrect": false
                            },
                            {
                              "text": "Kondisi pesawat yang rusak parah untuk tidak dipergunakan",
                              "isCorrect": true
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 92608,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 3,
                "point": 4,
                "created_at": "2024-08-07 17:12:12",
                "updated_at": "2024-08-07 17:12:12",
                "no": 3,
                "date_original": "2024-08-07",
                "date": "07 Aug 2024",
                "time": "17:12",
                "question_total": 4,
                "phase_name": "Modul 02 Klasifikasi Ruang Udara, Kebutuhan Operasional, KKOP, Pembatasan Penerbangan SPUKTA (Pengajuan Assessment)",
                "phase": {
                    "id": 3,
                    "name": "Modul 02 Klasifikasi Ruang Udara, Kebutuhan Operasional, KKOP, Pembatasan Penerbangan SPUKTA (Pengajuan Assessment)",
                    "start_phase": "2022-09-02 13:00:00",
                    "finish_phase": "2022-09-02 14:00:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 04:47:46",
                    "updated_at": "2023-08-01 14:01:52",
                    "quiz": [
                        {
                          "question": "Perum Lembaga Penyelenggara Pelayanan Navigasi Penerbangan Indonesia (LPPNPU) memverifikasi dan penerbitan",
                          "answers": [
                            {
                              "text": "Registrasi SPUKTA dan Sertifikat Remote Pilot",
                              "isCorrect": false
                            },
                            {
                              "text": "Pemberian Izin Operasi",
                              "isCorrect": false
                            },
                            {
                              "text": "Rekomendasi dan Assessment",
                              "isCorrect": true
                            },
                            {
                              "text": "Pemberian Security Clearance",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Direktorat Kelaikudaraan dan Pengoperasian Pesawat Udara (DKPPU) akan memverifikasi dan penerbitan",
                          "answers": [
                            {
                              "text": "Registrasi SPUKTA dan Sertifikat Remote Pilot",
                              "isCorrect": true
                            },
                            {
                              "text": "Pemberian Izin Operasi",
                              "isCorrect": false
                            },
                            {
                              "text": "Rekomendasi dan Assessment",
                              "isCorrect": false
                            },
                            {
                              "text": "Pemberian Security Clearance",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Direktorat Jenderal Strategi Pertahanan - Kementerian Pertahanan memverifikasi dan penerbitan",
                          "answers": [
                            {
                              "text": "Registrasi SPUKTA dan Sertifikat Remote Pilot",
                              "isCorrect": false
                            },
                            {
                              "text": "Pemberian Izin Operasi",
                              "isCorrect": false
                            },
                            {
                              "text": "Rekomendasi dan Assessment",
                              "isCorrect": false
                            },
                            {
                              "text": "Pemberian Security Clearance",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Direktorat Navigasi penerbangan (DNP) akan memverifikasi dan penerbitan",
                          "answers": [
                            {
                              "text": "Registrasi SPUKTA dan Sertifikat Remote Pilot",
                              "isCorrect": false
                            },
                            {
                              "text": "Pemberian Izin Operasi",
                              "isCorrect": true
                            },
                            {
                              "text": "Rekomendasi dan Assessment",
                              "isCorrect": false
                            },
                            {
                              "text": "Pemberian Security Clearance",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 91674,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 19,
                "point": 5,
                "created_at": "2024-08-07 12:31:10",
                "updated_at": "2024-08-07 12:31:10",
                "no": 4,
                "date_original": "2024-08-07",
                "date": "07 Aug 2024",
                "time": "12:31",
                "question_total": 5,
                "phase_name": "Modul 03 Informasi Cuaca Penerbangan Dan Pengaruh Cuaca terhadap Performa SPUKTA",
                "phase": {
                    "id": 19,
                    "name": "Modul 03 Informasi Cuaca Penerbangan Dan Pengaruh Cuaca terhadap Performa SPUKTA",
                    "start_phase": null,
                    "finish_phase": null,
                    "status": 1,
                    "is_renewal": 0,
                    "created_at": "2022-10-04 13:50:36",
                    "updated_at": "2024-08-05 16:03:43",
                    "quiz": [
                        {
                          "question": "Density (kerapatan) udara rendah, performa SPUKTA meningkat\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Pressure (tekanan) udara sama, temperatur lebih kecil, maka density (kerapatan) udara meningkat\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Microburst termasuk Low Level Windshear\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "METAR diterbitkan setiap 1 jam\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "TAFOR diperbarui setiap 6 jam\n              \n                            \n              \n              \n                \n                                                          \n                        \n                      Benar\n                    \n                                                                              \n                        \n                      Salah",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 91530,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 17,
                "point": 3,
                "created_at": "2024-08-07 12:24:38",
                "updated_at": "2024-08-07 12:54:40",
                "no": 5,
                "date_original": "2024-08-07",
                "date": "07 Aug 2024",
                "time": "12:24",
                "question_total": 3,
                "phase_name": "Modul 11 Operasi Bandara",
                "phase": {
                    "id": 17,
                    "name": "Modul 11 Operasi Bandara",
                    "start_phase": "2022-09-01 08:44:00",
                    "finish_phase": "2022-09-01 08:44:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 08:44:51",
                    "updated_at": "2024-08-05 16:03:46",
                    "quiz": [
                        {
                          "question": "Peraturan Menteri nomor berapakah yang mengatur tentang \naturan-aturan ruang udara dan penerbangan drone di Indonesia?",
                          "answers": [
                            {
                              "text": "PM 48",
                              "isCorrect": false
                            },
                            {
                              "text": "PM 90",
                              "isCorrect": false
                            },
                            {
                              "text": "PM 37",
                              "isCorrect": true
                            },
                            {
                              "text": "PM 63",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Apa yang dimaksud dengan AFUA (Advance Flexible Use of Airspace)?",
                          "answers": [
                            {
                              "text": "Tatanan ruang udara yang fleksibel untuk penggunaan penerbangan luar dan dalam negeri",
                              "isCorrect": false
                            },
                            {
                              "text": "Tatanan ruang udara yang dipergunakan \nseluas-luasnya untuk kepentingan yang prioritas selama memenuhi \nkeselamatan, keamanan dan efisiensi",
                              "isCorrect": true
                            },
                            {
                              "text": "Tatanan ruang udara yang kelola untuk kebutuhan militer dan sipil.",
                              "isCorrect": false
                            },
                            {
                              "text": "Tatanan ruang udara yang dipergunakan seluas-luasnya untuk kepentingan yang prioritas selama memenuhi kenyamanan penerbangan",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Apa itu daerah larangan penerbangan atau 'No Fly Zone' di Prohibited Area",
                          "answers": [
                            {
                              "text": "Ruang udara diatas Pangkalan Udara Militer, Latihan Militer dan Operasi Militer",
                              "isCorrect": false
                            },
                            {
                              "text": "Ruang udara diatas Istana Negara, Instalasi Nuklir, Obyek Vital Nasional",
                              "isCorrect": true
                            },
                            {
                              "text": "Ruang udara diatas Peluncuran Roket dan Satelit",
                              "isCorrect": false
                            },
                            {
                              "text": "Ruang udara diatas Mabes TNI, Kepolisian dan Lembaga Pertahanan Negara",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 91409,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 2,
                "point": 7,
                "created_at": "2024-08-07 12:18:40",
                "updated_at": "2024-08-07 13:57:49",
                "no": 6,
                "date_original": "2024-08-07",
                "date": "07 Aug 2024",
                "time": "12:18",
                "question_total": 10,
                "phase_name": "Modul 02 Klasifikasi Ruang Udara, Kebutuhan Operasional, KKOP, Pembatasan Penerbangan SPUKTA (Authorization)",
                "phase": {
                    "id": 2,
                    "name": "Modul 02 Klasifikasi Ruang Udara, Kebutuhan Operasional, KKOP, Pembatasan Penerbangan SPUKTA (Authorization)",
                    "start_phase": "2022-09-01 18:00:00",
                    "finish_phase": "2022-09-01 19:00:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 04:47:32",
                    "updated_at": "2023-08-01 14:01:50",
                    "quiz": [
                        {
                          "question": "Ada berapa Flight Information Region (FIR) di Indonesia",
                          "answers": [
                            {
                              "text": "Ada 67 Tower (Aerodrome Control Tower)",
                              "isCorrect": false
                            },
                            {
                              "text": "Ada 2 Regional (Jakarta dan Ujung Pandang)",
                              "isCorrect": false
                            },
                            {
                              "text": "Ada 37 ACC (Area Control Center) Units",
                              "isCorrect": false
                            },
                            {
                              "text": "Ada 211 AFIS (Aerodrome Flight Information Service)",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Peraturan Menteri nomor berapakah yang mengatur tentang ",
                          "answers": [
                            {
                              "text": "PM 48",
                              "isCorrect": false
                            },
                            {
                              "text": "PM 90",
                              "isCorrect": false
                            },
                            {
                              "text": "PM 37",
                              "isCorrect": true
                            },
                            {
                              "text": "PM 63",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Bagaimana kita mengetahui posisi controlled atau un-controlled airspaces",
                          "answers": [
                            {
                              "text": "Melalui laman https://imsis-djpu.dephub.go.id/",
                              "isCorrect": false
                            },
                            {
                              "text": "Melalui aplikasi Flight Radar 24",
                              "isCorrect": false
                            },
                            {
                              "text": "Melalui laman https://pia.airnavindonesia.co.id/navearth/",
                              "isCorrect": true
                            },
                            {
                              "text": "Mengamati apakah ada pesawat melintas di daerah tersebut",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Apakah kepanjangan dari KKOP?",
                          "answers": [
                            {
                              "text": "Kawasan Keselamatan Otoritas Penerbangan",
                              "isCorrect": false
                            },
                            {
                              "text": "Kawasan Keamanan Operasi Pesawat",
                              "isCorrect": false
                            },
                            {
                              "text": "Kawasan Keselamatan Operasi Penerbangan",
                              "isCorrect": true
                            },
                            {
                              "text": "Kawasan Keamanan Operasi Penerbangan",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Apa itu pelayanan Navigasi Penerbangan, kecuali",
                          "answers": [
                            {
                              "text": "Penyiapan informasi dan aturan penerbangan",
                              "isCorrect": true
                            },
                            {
                              "text": "Mengatur penerbangan dari mulai perencanaan sampai penerbangan bandara tujuan",
                              "isCorrect": false
                            },
                            {
                              "text": "Pelayanan fasilitas navigasi sesuai ketentuan perundang-undangan",
                              "isCorrect": false
                            },
                            {
                              "text": "Pengawasan angkutan penerbangan dari berbagai jenis pesawat dan rute penerbangan",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Apa yang dimaksud dengan AFUA (Advance Flexible Use of Airspace)?",
                          "answers": [
                            {
                              "text": "Tatanan ruang udara yang fleksibel untuk penggunaan penerbangan luar dan dalam negeri",
                              "isCorrect": false
                            },
                            {
                              "text": "Tatanan ruang udara yang dipergunakan \nseluas-luasnya untuk kepentingan yang prioritas selama memenuhi \nkeselamatan, keamanan dan efisiensi",
                              "isCorrect": true
                            },
                            {
                              "text": "Tatanan ruang udara yang kelola untuk kebutuhan militer dan sipil.",
                              "isCorrect": false
                            },
                            {
                              "text": "Tatanan ruang udara yang dipergunakan seluas-luasnya untuk kepentingan yang prioritas selama memenuhi kenyamanan penerbangan",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Satuan-satuan dalam dunia penerbangan adalah ",
                          "answers": [
                            {
                              "text": "Kilometer, Lat-Long, Km/hr, WITA, Meter",
                              "isCorrect": false
                            },
                            {
                              "text": "Mile, Lat-Long, UTC, Yard",
                              "isCorrect": false
                            },
                            {
                              "text": "Nautical-Mile, UTM, UTC, FT",
                              "isCorrect": true
                            },
                            {
                              "text": "Semua benar",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Apa itu daerah larangan penerbangan atau 'No Fly Zone' di Prohibited Area",
                          "answers": [
                            {
                              "text": "Ruang udara diatas Pangkalan Udara Militer, Latihan Militer dan Operasi Militer",
                              "isCorrect": false
                            },
                            {
                              "text": "Ruang udara diatas Istana Negara, Instalasi Nuklir, Obyek Vital Nasional",
                              "isCorrect": true
                            },
                            {
                              "text": "Ruang udara diatas Peluncuran Roket dan Satelit",
                              "isCorrect": false
                            },
                            {
                              "text": "Ruang udara diatas Mabes TNI, Kepolisian dan Lembaga Pertahanan Negara",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Apakah yang dimaksud batasan daerah KKOP?",
                          "answers": [
                            {
                              "text": "Daerah dalam radius 6km dari kedua ujung landasan pacu bandara",
                              "isCorrect": false
                            },
                            {
                              "text": "Daerah dalam radius 8km dari kedua ujung landasan pacu bandara",
                              "isCorrect": false
                            },
                            {
                              "text": "Daerah dalam radius 10km dari titik ujung bandara",
                              "isCorrect": false
                            },
                            {
                              "text": "Daerah dalam radius 15km kedua ujung landasan pacu bandara",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Sebutkan Klasifikasi Ruang Udara yang dilayani di Indonesia",
                          "answers": [
                            {
                              "text": "Civil Aviation, Military Area, Training Zone",
                              "isCorrect": true
                            },
                            {
                              "text": "Prohibited, Restricted, Danger Zone",
                              "isCorrect": false
                            },
                            {
                              "text": "Controlled Airspace, KKOP, Pengoperasioan SPUKTA, Un-controlled Airspace",
                              "isCorrect": false
                            },
                            {
                              "text": "Kawasan Keselamatan Operasi Penerbangan",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 91080,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 7,
                "point": 5,
                "created_at": "2024-08-06 16:01:10",
                "updated_at": "2024-08-06 16:01:10",
                "no": 7,
                "date_original": "2024-08-06",
                "date": "06 Aug 2024",
                "time": "16:01",
                "question_total": 5,
                "phase_name": "Modul 04 Beban Dan Performa Pada Spukta",
                "phase": {
                    "id": 7,
                    "name": "Modul 04 Beban Dan Performa Pada Spukta",
                    "start_phase": "2022-09-01 08:27:00",
                    "finish_phase": "2022-09-01 08:27:00",
                    "status": 1,
                    "is_renewal": 0,
                    "created_at": "2022-09-01 08:27:53",
                    "updated_at": "2024-01-08 07:29:28",
                    "quiz": [
                        {
                          "question": "Berikut adalah gerakan Hover",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Udara mengalir lebih cepat menjadikan tekanan berkurang",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Rudder berfungsi untuk menggeser nose pesawat ke arah naik dan turun",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Lift sebanding dengan Drag dan Thrust sebanding dengan Weight",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Berikut adalah gerakan YAW",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 90619,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 1,
                "point": 2,
                "created_at": "2024-08-06 13:02:46",
                "updated_at": "2024-08-06 13:02:46",
                "no": 8,
                "date_original": "2024-08-06",
                "date": "06 Aug 2024",
                "time": "13:02",
                "question_total": 5,
                "phase_name": "Modul 01-Sesi Siang-Peraturan yang berlaku untuk Sistem Pesawat Udara Tanpa Awak (Sertifikat, Registrasi, Pengecualian, R Udara, Persetujuan, Pengawasan, Sangsi)",
                "phase": {
                    "id": 1,
                    "name": "Modul 01-Sesi Siang-Peraturan yang berlaku untuk Sistem Pesawat Udara Tanpa Awak (Sertifikat, Registrasi, Pengecualian, R Udara, Persetujuan, Pengawasan, Sangsi)",
                    "start_phase": "2022-09-01 12:00:00",
                    "finish_phase": "2022-09-01 13:00:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 04:47:20",
                    "updated_at": "2023-08-01 14:01:49",
                    "quiz": [
                        {
                          "question": "Kaidah BVLOS sesuai aturan mewajibkan kemampuan SPUKTA, kecuali",
                          "answers": [
                            {
                              "text": "Kemampuan deteksi",
                              "isCorrect": false
                            },
                            {
                              "text": "Kemampuan menghindar",
                              "isCorrect": false
                            },
                            {
                              "text": "Kemampuan pelacak gerakan",
                              "isCorrect": true
                            },
                            {
                              "text": "Kemampuan radar monitoring",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Kawasan KKOP bandara mana yang paling berbahaya untuk menerbangkan SPUKTA",
                          "answers": [
                            {
                              "text": "Kawasan 3 km dari ujung lintasan take-off / landing",
                              "isCorrect": false
                            },
                            {
                              "text": "Kawasan radius 5,5 km KKOP",
                              "isCorrect": false
                            },
                            {
                              "text": "Kawasan 1 km dari samping tegak lurus run-way",
                              "isCorrect": false
                            },
                            {
                              "text": "Kawasan didalam No Fly Zone",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Verifikasi dokumen apa yang dibutuhkan oleh DKPPU dalam sistem SIDOPI, kecuali",
                          "answers": [
                            {
                              "text": "Jam terbang pilot dan pengalaman operasi",
                              "isCorrect": true
                            },
                            {
                              "text": "Sertifikat kompetensi pilot",
                              "isCorrect": false
                            },
                            {
                              "text": "Identifikasi bahaya dan penilaian resiko operasi yang diajukan",
                              "isCorrect": false
                            },
                            {
                              "text": "Kepemilikan SPUKTA yang di ajukan",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Berikut merupakan aspek resiko-resiko keselamatan operasi, kecuali",
                          "answers": [
                            {
                              "text": "Adanya manusia di darat",
                              "isCorrect": false
                            },
                            {
                              "text": "Adanya infrastrutur yang penting",
                              "isCorrect": false
                            },
                            {
                              "text": "Pengguna ruang udara lain",
                              "isCorrect": false
                            },
                            {
                              "text": "Privasi suatu bangunan atau kawasan",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Merupakan ketentuan pengoperasian, kecuali",
                          "answers": [
                            {
                              "text": "Kecepatan maksimum 161 km/jam",
                              "isCorrect": false
                            },
                            {
                              "text": "150m dibawah awan",
                              "isCorrect": false
                            },
                            {
                              "text": "600m jarak horizontal dari awan",
                              "isCorrect": false
                            },
                            {
                              "text": "Baterai tidak lebih dari 160 Watt hours",
                              "isCorrect": true
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 90398,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 22,
                "point": 6,
                "created_at": "2024-08-06 12:25:59",
                "updated_at": "2024-08-06 12:25:59",
                "no": 9,
                "date_original": "2024-08-06",
                "date": "06 Aug 2024",
                "time": "12:25",
                "question_total": 10,
                "phase_name": "Modul 01-Sesi Pagi-Peraturan yang berlaku untuk Sistem Pesawat Udara Tanpa Awak (Pengoperasian)",
                "phase": {
                    "id": 22,
                    "name": "Modul 01-Sesi Pagi-Peraturan yang berlaku untuk Sistem Pesawat Udara Tanpa Awak (Pengoperasian)",
                    "start_phase": null,
                    "finish_phase": null,
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-10-04 15:55:08",
                    "updated_at": "2023-08-01 14:02:02",
                    "quiz": [
                        {
                          "question": "Berikut merupakan prosedur operasi SPUKTA berijin sesuai PM 37, kecuali",
                          "answers": [
                            {
                              "text": "Melaporkan 24 jam sebelum pengoperasian, selama operasi dan setelah operasi",
                              "isCorrect": false
                            },
                            {
                              "text": "Melakukan komunikasi melalui radio dua arah dan/atau telepon genggam",
                              "isCorrect": false
                            },
                            {
                              "text": "Melakukan penerbangan dimana saja selama pengamatan ruang udara tidak ada pesawat atau obyek terbang lain",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Pengoperasian SPUKTA diluar Hobby dan Rekreasi adalah selain kegiatan hanya untuk Hobby dan rekreasi, tidak lebih berat dari 25kg, pilot harus bersetifikat kompetensi, sesuai CASR (PKPS) 107 semua bagian ",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Lisensi Pilot pesawat terbang berawak bisa dipergunakan untuk pengoperasian SPUKTA",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Sesuai PKPS bagian 107, waktu pengoperasian SPUKTA adalah siang hari",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Pengoperasian SPUKTA untuk Hobby dan Rekreasi adalah kegiatan hanya untuk Hobby dan rekreasi saja, tidak lebih berat dari 7kg, berbasis komunitas, sesuai CASR (PKPS) 107 bagian 107.2 ",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Difinisi Sistem Pesawat Udara Kecil Tanpa Awak adalah sistem pesawat udara kecil tanpa awak dan elemen terkait (termasuk jalur komunikasi dan komponen pengendali PUKTA) ",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Berikut Pengoperasian SPUKTA wajib dengan perijinan, kecuali",
                          "answers": [
                            {
                              "text": "Operasi di kawasan tertentu ditentukan oleh instansi berwewenang setempat",
                              "isCorrect": false
                            },
                            {
                              "text": "Controlled dan Un-controlled airspace diatas 120m AGL",
                              "isCorrect": false
                            },
                            {
                              "text": "Operasi di kawasan 3 Nautical Mile dari koordinat helipad",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Berikut merupakan pelanggaran operasi SPUKTA sesuai PM 37, kecuali",
                          "answers": [
                            {
                              "text": "Melanggar wilayah kedaulatan, keamanan dan mengancam keselamatan penerbangan",
                              "isCorrect": false
                            },
                            {
                              "text": "Beroperasi sesuai dengan persetujuan yang diberikan",
                              "isCorrect": true
                            },
                            {
                              "text": "Memiliki dampak ancaman terhadap aset negara, obyek vital dan keselamatan negara",
                              "isCorrect": false
                            },
                            {
                              "text": "Tidak berkoordinasi dan memiliki persetujuan operasi",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Berikut merupakan obyek pengawasan SPUKTA sesuai PM 37, kecuali",
                          "answers": [
                            {
                              "text": "Persetujuan pengoperasian dari instansi kawasan terkait",
                              "isCorrect": false
                            },
                            {
                              "text": "Registrasi pesawat, kelaikudaraan pesawat dan sertifikat pilot/operator SPUKTA",
                              "isCorrect": false
                            },
                            {
                              "text": "Pengoperasian di ruang udara yang memerlukan NOTAM dan ijin keamanan",
                              "isCorrect": false
                            },
                            {
                              "text": "Pengawasan langsung terhadap potensi ancaman dan keamanan negara",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Pengaturan sesuai PM 37 tahun 2020, kecuali",
                          "answers": [
                            {
                              "text": "Mengatur pengoperasion SPUKTA untuk keselamatan dan keamanan penerbangan",
                              "isCorrect": false
                            },
                            {
                              "text": "Mewajibkan SPUKTA untuk didaftartan sebagai milik perorangan, milik badan hukum",
                              "isCorrect": true
                            },
                            {
                              "text": "Mengatur mengenai pengawasan, sanksi dan aturan perundangan di Indonesia",
                              "isCorrect": false
                            },
                            {
                              "text": "Mengatur kelaikan dan kompetensi pilot SPUKTA",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 89858,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 14,
                "point": 5,
                "created_at": "2024-08-05 16:42:32",
                "updated_at": "2024-08-05 16:42:32",
                "no": 10,
                "date_original": "2024-08-05",
                "date": "05 Aug 2024",
                "time": "16:42",
                "question_total": 5,
                "phase_name": "Modul 08 Penentuan Performa Pesawat Udara Kecil Tanpa Awak (SPUKTA)",
                "phase": {
                    "id": 14,
                    "name": "Modul 08 Penentuan Performa Pesawat Udara Kecil Tanpa Awak (SPUKTA)",
                    "start_phase": "2022-09-01 08:43:00",
                    "finish_phase": "2022-09-01 08:43:00",
                    "status": 1,
                    "is_renewal": 0,
                    "created_at": "2022-09-01 08:43:33",
                    "updated_at": "2024-01-08 07:29:48",
                    "quiz": [
                        {
                          "question": "Semakin banyak motor akan menimbulkan sistem terbang yang lebih stabil dan hemat baterai",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Keunggulan multirotor adalah kemampuan manuver lebih besar dan kurang stabil terhadap angin",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Density altitude disebut sebagai ketinggian tekanan terkoreksi pada variasi suhu yang tidak stabil",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Komponen SPUKTA yang mengatur putaran motor adalah Elektric Speed Control",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Ketinggian absolut adalah ketinggian dari atas permukaan tanah (AGL)",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 89714,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 9,
                "point": 3,
                "created_at": "2024-08-05 16:35:34",
                "updated_at": "2024-08-05 16:35:34",
                "no": 11,
                "date_original": "2024-08-05",
                "date": "05 Aug 2024",
                "time": "16:35",
                "question_total": 5,
                "phase_name": "Modul 05 Prosedur Darurat",
                "phase": {
                    "id": 9,
                    "name": "Modul 05 Prosedur Darurat",
                    "start_phase": "2022-09-01 08:28:00",
                    "finish_phase": "2022-09-01 08:28:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 08:28:28",
                    "updated_at": "2024-08-05 16:03:02",
                    "quiz": [
                        {
                          "question": "Yang dimaksud dengan “Fly away” di dalam penerbangan drone adalah ",
                          "answers": [
                            {
                              "text": "Kondisi SPUKTA terbang otomatis tanpa kontrol manual.",
                              "isCorrect": false
                            },
                            {
                              "text": "Terbang SPUKTA yang kesana kemari karena terpurusnya signal GPS",
                              "isCorrect": false
                            },
                            {
                              "text": "Kondisi SPUKTA tidak dapat dikendalikan atau pilot tidak dapat mengambil alih kendali tanpa kontrol pilot.",
                              "isCorrect": true
                            },
                            {
                              "text": "Semua benar",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "SPUKTA modern menggunakan frekuensi 433 MHz dan 915 MHz",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Gangguan / kehilangan tautan perintah dan kendali terbang dikarenakan oleh interferensi elektromagnetik",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Untuk menyimpan batere lipo dalam waktu cukup lama sebaiknya ",
                          "answers": [
                            {
                              "text": "Disimpan ditempat kering dan dingin",
                              "isCorrect": false
                            },
                            {
                              "text": "Diletakkan didalam kompartemen yang kedap",
                              "isCorrect": false
                            },
                            {
                              "text": "Distorage sampai isi batere lipo antara 30% - 50%",
                              "isCorrect": true
                            },
                            {
                              "text": "Di kosongkan isi batere lipo nya.",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Merupakan prosedur standar pada saat penerbangan drone, kecuali",
                          "answers": [
                            {
                              "text": "Tidak menerbangkan drone di lintasan penerbangan pesawat udara melebihi ketinggian 120 meter.",
                              "isCorrect": false
                            },
                            {
                              "text": "Mengaktifkan mode failsafe (dan seting ketinggian RTH)",
                              "isCorrect": false
                            },
                            {
                              "text": "Memperhitungkan kemampuan pesawat dan pengaruh cuaca yang dihadapi",
                              "isCorrect": false
                            },
                            {
                              "text": "Berhati-hati jika terbang diatas kerumunan manusia",
                              "isCorrect": true
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 88816,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 18,
                "point": 5,
                "created_at": "2024-08-05 13:04:34",
                "updated_at": "2024-08-05 13:04:34",
                "no": 12,
                "date_original": "2024-08-05",
                "date": "05 Aug 2024",
                "time": "13:04",
                "question_total": 5,
                "phase_name": "Modul 12 Prosedur Inspeksi Permulaan Terbang dan Perawatan SPUKTA",
                "phase": {
                    "id": 18,
                    "name": "Modul 12 Prosedur Inspeksi Permulaan Terbang dan Perawatan SPUKTA",
                    "start_phase": "2022-09-01 08:45:00",
                    "finish_phase": "2022-09-01 08:45:00",
                    "status": 1,
                    "is_renewal": 1,
                    "created_at": "2022-09-01 08:45:27",
                    "updated_at": "2024-08-05 16:03:50",
                    "quiz": [
                        {
                          "question": "Prosedur Inspeksi Battery Lithium Polimer, kecuali",
                          "answers": [
                            {
                              "text": "Voltasi tiap sel harus rata, Voltase tidak boleh dibawah 3,4V, Tersimpan pada 30-50%",
                              "isCorrect": false
                            },
                            {
                              "text": "Dipasang konektor plug tambahan, Kapasitas penyimpanan diatas 80%, Tersimpan pada Lipo Bag",
                              "isCorrect": false
                            },
                            {
                              "text": "Voltasi tiap sel harus rata, Voltase tidak boleh dibawah 3,4 V, Tersimpan pada kondisi kosong%",
                              "isCorrect": true
                            },
                            {
                              "text": "Battery diberi label identifikasi untuk memudahkan dalam pencatatan dan inspeksi",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Sesuai Surat Edaran Kemenhub mengenai battery lithium, kecuali",
                          "answers": [
                            {
                              "text": "Baterai harus dilepas dari pesawat dan dibawa di kabin pesawat",
                              "isCorrect": false
                            },
                            {
                              "text": "Tidak melebihi daya jam 100Wh dan jika melebihi 160Wh harus mengurus persetujuan dari Maskapai",
                              "isCorrect": false
                            },
                            {
                              "text": "Baterai tanpa identifikasi daya jam harus mengurus persetujuan dari Maskapai",
                              "isCorrect": true
                            },
                            {
                              "text": "Baterai lithium atau cadangan yang diizinkan dibawa setiap penumpang sebanyak 2 unit",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Pre-flight Inspection bertujuan untuk",
                          "answers": [
                            {
                              "text": "Pemeriksaan Visual dan Kondisi bagian-bagian pesawat",
                              "isCorrect": false
                            },
                            {
                              "text": "Mengetahui fungsi sesuai dengan buku petunjuk penggunaan",
                              "isCorrect": false
                            },
                            {
                              "text": "Meyakinkan fungsi dan sistem integrasi pesawat, remote dan instrumen bekerja dengan baik",
                              "isCorrect": false
                            },
                            {
                              "text": "Semua benar",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Rekomendasi terbaik dalam inspeksi dan perawatan, kecuali",
                          "answers": [
                            {
                              "text": "Menggunakan checklist pemeriksaan, sesuai buku petunjuk dari pabrikan",
                              "isCorrect": false
                            },
                            {
                              "text": "Mengisi buku pemeriksaan dan diperiksa menggunakan software pendukung",
                              "isCorrect": false
                            },
                            {
                              "text": "Mengisi checklist dan buku pemeriksaan walaupun tidak terlalu pahan maksudnya",
                              "isCorrect": true
                            },
                            {
                              "text": "Buku pemeriksaan ditujukan untuk pihak yang lebih tau mengenai kronologi pesawat dan pemakaiannya.",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Indikasi adanya penurunan fungsi atau kondisi fisik pesawat",
                          "answers": [
                            {
                              "text": "Adanya suara/indikator error saat dinyalakan",
                              "isCorrect": false
                            },
                            {
                              "text": "Terukur adanya deformasi atau ketidak sesuaian ukuran",
                              "isCorrect": false
                            },
                            {
                              "text": "Perubahan gerakan/getaran, bentuk/kembung, putusnya interkoneksi",
                              "isCorrect": false
                            },
                            {
                              "text": "Semua benar",
                              "isCorrect": true
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 88743,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 11,
                "point": 4,
                "created_at": "2024-08-05 12:59:25",
                "updated_at": "2024-08-05 12:59:25",
                "no": 13,
                "date_original": "2024-08-05",
                "date": "05 Aug 2024",
                "time": "12:59",
                "question_total": 5,
                "phase_name": "Modul 07 Prosedur Komunikasi Radio",
                "phase": {
                    "id": 11,
                    "name": "Modul 07 Prosedur Komunikasi Radio",
                    "start_phase": "2022-09-01 08:28:00",
                    "finish_phase": "2022-09-01 08:28:00",
                    "status": 1,
                    "is_renewal": 0,
                    "created_at": "2022-09-01 08:29:00",
                    "updated_at": "2024-01-08 07:29:30",
                    "quiz": [
                        {
                          "question": "Dalam komunikasi radio penerbangan SPUKTA dibutuhkan suara keras dan sesuai kewajiban berbahasa Inggris",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "CTAF adalah frekuensi yang digunakan oleh pesawat udara di",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Berbicara tanpa radio, dalam berbicara harus jelas ",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Melaporkan selesai pekerjaan adalah dilakukan setelah saat landing dan mematikan SPUKTA",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Berikut adalah tugas pilot yang paling utama",
                          "answers": [
                            {
                              "text": "Aviate",
                              "isCorrect": false
                            },
                            {
                              "text": "Navigate",
                              "isCorrect": false
                            },
                            {
                              "text": "Keep calm / No panic",
                              "isCorrect": false
                            },
                            {
                              "text": "Communicate",
                              "isCorrect": true
                            }
                          ]
                        }
                      ]
                }
            },
            {
                "id": 88700,
                "attendance_id": 10307,
                "capture": null,
                "image": null,
                "phase_id": 15,
                "point": 4,
                "created_at": "2024-08-05 12:54:36",
                "updated_at": "2024-08-05 12:54:36",
                "no": 14,
                "date_original": "2024-08-05",
                "date": "05 Aug 2024",
                "time": "12:54",
                "question_total": 5,
                "phase_name": "Modul 09 Pengaruh Fisiologis Narkoba & Alkohol",
                "phase": {
                    "id": 15,
                    "name": "Modul 09 Pengaruh Fisiologis Narkoba & Alkohol",
                    "start_phase": "2022-09-01 08:43:00",
                    "finish_phase": "2022-09-01 08:43:00",
                    "status": 1,
                    "is_renewal": 0,
                    "created_at": "2022-09-01 08:44:00",
                    "updated_at": "2024-01-08 07:29:48",
                    "quiz": [
                        {
                          "question": "Faktor fisiologis penting yang harus pilot perhatikan",
                          "answers": [
                            {
                              "text": "Hyperventilation, Stress",
                              "isCorrect": false
                            },
                            {
                              "text": "Fatigue, Dehydration",
                              "isCorrect": false
                            },
                            {
                              "text": "Semua Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Heatstroke",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Limit Alkohol dalam darah yang dibolehkan saat menerbangkan drone atau mengendarai kendaraan bermotor",
                          "answers": [
                            {
                              "text": "0.27 %  sd 0.4 % kandungan alcohol",
                              "isCorrect": false
                            },
                            {
                              "text": "0.08 % sd 0.1 % Kandungan Alkohol",
                              "isCorrect": false
                            },
                            {
                              "text": "0.35 % sd 0.5 % kandungan alcohol",
                              "isCorrect": false
                            },
                            {
                              "text": "Semua benar",
                              "isCorrect": true
                            }
                          ]
                        },
                        {
                          "question": "Remote Pilot dibolehkan menkonsumsi makanan dan minuman serta obat-obatan yang tidak berefek samping",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": true
                            },
                            {
                              "text": "Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Indra terpenting saat menerbangkan drone",
                          "answers": [
                            {
                              "text": "Hidung",
                              "isCorrect": false
                            },
                            {
                              "text": "Mata",
                              "isCorrect": true
                            },
                            {
                              "text": "Telinga",
                              "isCorrect": false
                            },
                            {
                              "text": "Semua Salah",
                              "isCorrect": false
                            }
                          ]
                        },
                        {
                          "question": "Dalam kondisi Pilot tidak fit kita memaksakan tetap melakukan penerbangan",
                          "answers": [
                            {
                              "text": "Benar",
                              "isCorrect": false
                            },
                            {
                              "text": "Salah",
                              "isCorrect": true
                            }
                          ]
                        }
                      ]
                }
            }
        ]
    }
    //sort data by phase_id
    return data.data.sort((a, b) => a.phase_id - b.phase_id)
}

module.exports = {getQuestion}