import { useEffect, useState } from 'react';
import './App.css';
import { getQuestion } from './controller/QuestionController';

function App() {
  const [dataQuestion, setDataQuestion] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getQuestion().then((res) => {
      setDataQuestion(res);
      setFilteredQuestions(res); // Initialize with full questions
    });
  }, []);

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      setFilteredQuestions(dataQuestion); // Show all if search term is empty
    } else {
      const filtered = dataQuestion.filter(value =>
        value.phase.quiz.some(quiz =>
          quiz.question.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredQuestions(filtered);
    }
  };

  return (
    <div className="body">
      <input 
        type="text" 
        placeholder="Search question" 
        className="search-bar" 
        value={searchTerm} 
        onChange={(e) => setSearchTerm(e.target.value)} 
      />
      <button className="search-button" onClick={handleSearch}>Search</button>
      
      <header className="App-header">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((value, phaseIndex) => (
            <div key={phaseIndex}>
              <h2 className='question'>{value.phase_name}</h2>
              {value.phase.quiz.map((quiz, quizIndex) => (
                <div className='quiz-container' key={quizIndex}>
                  <div>{quiz.question}</div>
                  {quiz.answers && (
                    <ul className='options'>
                      {quiz.answers.map((answer, answerIndex) => (
                        <li key={answerIndex}>
                          <input type="radio" value={answer.text} checked={answer.isCorrect} readOnly />
                          <label htmlFor={`option-${phaseIndex}-${quizIndex}-${answerIndex}`}>
                            {answer.text}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : (
          <p>No questions found.</p>
        )}
      </header>
    </div>
  );
}

export default App;
